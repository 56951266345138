import React, {useState} from 'react';
import Markdown from "markdown-to-jsx";
var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "14px" }} {...props}>
      {children}
    </a>
  );
};

const ImageAttachments = ({content}) => {
    const [formId, setFormId] = useState(null);
    let formName;

    var Icon = classNames({
      Icon: true,
      IconUser: true
    });
  
      
    return (
      <div style={{display:'flex'}} className="workflowForm">
     {!window.config.isNewUI &&  <div className={Icon}></div>}
      <div className="botTextBox imageBorder" style={{width:'80%',height:'100%'}}>
            <img src={`data:image/png;base64,${content.imageUrl}`} alt={content.name} className="imageSize" />
            <div class="conv-text" style={{ margin: "8px" }}>
                <Markdown
                  options={{
                    overrides: {
                      forceBlock: true,
                      forceInline: false,
                      a: {
                        component: MyLink,
                        target: "_blank",
                        props: {
                          className: "markdown-link",
                          target: "_blank"
                        }
                      }
                    }
                  }}
                >
                  {content.text}
                </Markdown>
              </div>
        </div> 
      </div>
    );
}

export default ImageAttachments;

